.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  width: 100%;
  height: 100vh;
}

#root, .App-root {
  width: 100%;
  height: 100%;
}

.App-header {
  width: calc(100% - 16pt);
  height: 14pt;
  display: block;
  padding: 8pt;
}

.App-header > ul {
  display: inline;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.App-header > ul > li {
  display: inline;
  font-size: calc(12pt);
  margin: 4pt 8pt 4pt 8pt;
  color: white;
}

.App-content {
  width: calc(100% - 16pt);
  height: calc(100% - 48pt);
  margin: 8pt;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
